<template>
  <div id="home-page">
    <HeroComp class="page_section scroll-snap" />
    <AboutUsComp class="page_section scroll-snap" />
    <FeaturesComp class="page_section" />
    <OurProductsComp class="page_section" />
    <FamousComp v-if="isMobile || isTablet" class="page_section" />
    <FamousCompDesk v-else class="page_section" />
    <TulumSection class="page_section" />
    <TestimonialsComp class="page_section" />
    <TendenciasComp class="page_section" />
    <Footer class="page_section" />
  </div>
</template>
<script>
import HeroComp from './components/HeroComp.vue'
import AboutUsComp from './components/AboutUsComp.vue'
import FeaturesComp from './components/FeaturesTest.vue'
import OurProductsComp from './components/OurProductsComp.vue'
import FamousComp from './components/FamousComp.vue'
import FamousCompDesk from './components/FamousCompDesk.vue'
import TulumSection from './components/TulumSection.vue'
import TestimonialsComp from './components/TestimonialsComp.vue'
import TendenciasComp from './components/TendenciasComp.vue'
import Footer from 'Components/footer/Footer.vue'
import useSlideAnimations from '@/composables/slideAnimations.js'
import mobileChecker from '@/mixins/mobileChecker'

export default {
  mixins: [mobileChecker],

  beforeCreate() {
    document.title = 'FabricTienda - Home'
  },
  mounted() {
    document.addEventListener('scroll', useSlideAnimations)

    if (this.$route.query.section) {
      this.scrollTo(this.$route.query.section)
    } else {
      window.scrollTo(0, 0)
    }
  },
  beforeUnmount() {
    document.removeEventListener('scroll', useSlideAnimations)
  },
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth'
      })
    }
  },
  components: {
    HeroComp,
    AboutUsComp,
    FeaturesComp,
    OurProductsComp,
    TulumSection,
    TestimonialsComp,
    TendenciasComp,
    FamousComp,
    FamousCompDesk,
    Footer
  }
}
</script>
<style lang="scss" scoped>
#home-page {
  position: relative;
}
</style>
