<template>
  <div id="famous-comp-desk">
    <div class="sc-main scroll-snap">
      <div class="sc-container">
        <div class="sc-wrap">
          <div class="sc-title">
            <div class="sc-star"></div>
            <h4>Cada vez son más los hogares que confían en nosotros</h4>
            <span
              >Ellos confiaron en nosotros y ahora disfrutan del confort de nuestros sillones.
              <br />
              ¡Gracias por se parte de nuestra historia!</span
            >
          </div>
          <div class="sc-content">
            <div class="bg-abs"></div>
            <swiper
              :slidesPerView="5.5"
              :spaceBetween="25"
              :navigation="true"
              :pagination="false"
              :loop="true"
              :modules="modules"
              class="mySwiperFamDesk"
            >
              <swiper-slide v-for="item in intercalatedData" :key="item.id">
                <div class="s-item">
                  <a v-if="item.link" :href="item.link" target="_blank"
                    ><i class="fa-solid fa-arrow-up-right-from-square"></i>Ver</a
                  >
                  <img :src="item.image" />
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Famclients from '@/data/famclients.json'
import Clienthistorias from '@/data/clienthistorias.json'
import { Swiper, SwiperSlide } from 'swiper/vue'
import mobileChecker from '@/mixins/mobileChecker'

// Import Swiper styles
import 'swiper/css'

import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/thumbs'
// import required modules
import { FreeMode, Navigation, Pagination, Thumbs } from 'swiper'

export default {
  mixins: [mobileChecker],
  data() {
    return {}
  },
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper
    }
  },
  computed: {
    intercalatedData() {
      const result = []
      const maxLength = Math.max(Famclients.data.length, Clienthistorias.data.length)

      for (let i = 0; i < maxLength; i++) {
        if (Famclients.data[i]) result.push(Famclients.data[i]) // Add from json1
        if (Clienthistorias.data[i]) result.push(Clienthistorias.data[i]) // Add from json2
      }

      return result
    }
  },
  setup() {
    return {
      modules: [FreeMode, Pagination, Navigation, Thumbs]
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'swiper/css/pagination';
#famous-comp-desk {
  width: 100%;
  max-width: 2200px;
  margin: auto;
  max-height: 1500px;

  @media (max-width: 767px) {
    max-height: unset;
  }
  @media (min-width: 768px) {
    max-height: unset;
  }
  @media (min-width: 992px) {
    max-height: 1500px;
  }

  .sc-main {
    height: calc(100vh - var(--header-height));
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding: 2rem 0;

    @media (max-width: 767px) {
      height: unset;
    }
    @media (min-width: 768px) {
      height: unset;
    }
    @media (min-width: 992px) {
      height: calc(100vh - var(--header-height));
    }
  }

  .sc-container {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .sc-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }

  .sc-title {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    padding-left: 6.5rem;
    position: relative;

    @media (min-width: 1200px) {
      margin-bottom: 2.5rem;
    }
    @media (min-width: 1800px) {
      margin-bottom: 3.5rem;
    }

    h4 {
      width: 600px;
      text-wrap: balance;
      position: relative;
      z-index: 1;
    }

    span {
      position: relative;
      z-index: 1;
    }

    /* HTML: <div class="star"></div> */
    .sc-star {
      position: absolute;
      left: 60px;
      top: -20px;
      width: 120px;
      aspect-ratio: 1;
      background: #fef6e9;
      clip-path: polygon(
        50% 0,
        calc(50% * (1 + sin(0.4turn))) calc(50% * (1 - cos(0.4turn))),
        calc(50% * (1 - sin(0.2turn))) calc(50% * (1 - cos(0.2turn))),
        calc(50% * (1 + sin(0.2turn))) calc(50% * (1 - cos(0.2turn))),
        calc(50% * (1 - sin(0.4turn))) calc(50% * (1 - cos(0.4turn)))
      );
    }
  }

  .sc-content {
    width: 100%;
    padding-left: 6.5rem;
    position: relative;

    .bg-abs {
      width: 100%;
      height: 300px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: #fef6e9;

      @media (max-width: 576px) {
      }
      @media (min-width: 577px) {
      }
      @media (min-width: 768px) {
      }
      @media (min-width: 992px) {
        height: 300px;
      }
      @media (min-width: 1200px) {
        height: 300px;
      }
      @media (min-width: 1800px) {
        height: 350px;
      }
    }
  }

  .mySwiperFamDesk {
    .swiper-slide {
      height: 100%;

      .s-item {
        height: 100%;
        position: relative;
        user-select: none;

        a {
          position: absolute;
          top: 5px;
          right: 5px;
          border-radius: 2rem;
          background-color: $color-primary;
          color: $color-white;
          height: 23px;
          font-size: 13px;
          transition: all 0.3s ease-in-out;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          gap: 0.4rem;
          padding: 0 0.5rem;

          &:hover {
            background-color: darken($color-primary, 10%);
          }
        }

        img {
          height: auto;
          aspect-ratio: 4/7;
          width: 100%;
          object-fit: cover;
          border-radius: 0.8rem;
        }
      }
    }
  }
}
</style>
